/* ==========================================================================
   Section 0
========================================================================== */
#home-section-0{ 

    .container-head {
        display: flex;
        height: 100%;

        .col-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 700px;
            max-width: 700px;
        }

        .head-button {
            margin-top: 25px;
        }

        .col-img {
            margin-top: -100px;
            flex-grow: 1;
            display: flex;
            align-items: center;

            img {
                height: auto;
                width: 100%;
            }
        }
    }
   
}


/* ==========================================================================
   Section 1
========================================================================== */
#home-section-1{
    background-image: url("../../img/home/background-green.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100%;
    margin-top: -20vh;
    height: 90vh;

    .container {
        .row {
            margin-top: 40vh;
            height: 100%;

            .wow {
                height: 100%;
                padding: 0 25px;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    height: 80px;
                    margin-bottom: 40px;
                }

                p {
                    margin-top: 25px;
                    font-size: 18px;
                    text-align: center;
                    text-justify: inter-word;
                }
            }

            .line {
                top: 65px;
                right: 0;
                position: absolute;
                width: 2px;
                height: 140px;
                background-color: #ccc;
                transform: rotate(90deg);
            }
        }
    }
}


/* ==========================================================================
   Section 2
========================================================================== */
#home-section-2{
    margin-top: 0px;
    background-color: var(--dark);

    .splide__slide {
        background-color: white;
        margin: 10px;
        border-radius: 10px;
        text-align: center;
        padding: 15px;
        width: calc(20% - 25px) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .text-box {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 15px 0;

            p {
                text-align: center;
                font-size: 20px;
            }
        }

       

        .img-box {
            height: 200px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width : 150px;
                height: auto;
                margin: 25px;
            }
        }

        a {
            color: #3f4254;
            font-weight: 400 !important;
        }
        
    }

    .splide__arrow--prev {
        left: -50px;
    }

    .splide__arrow--next {
        right: -50px;
    }

    .splide__pagination {
        bottom: -1em;
    }
}


/* ==========================================================================
   Section 3
========================================================================== */
#home-section-3{
    margin-top: 10vh;
    background-image: url("../../img/home/fond-droit.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;

    .row > * {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h5 {
            font-size: 17px;
            text-transform: uppercase;
        }
        
        p {
            margin-top: 10px;
            font-size: 14px;
        }
    }
    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary);
        height: 80px;
        width: 80px;
        border-radius: 50%;
        margin-bottom: 25px;
        img {
            width: 50px;
            height: auto;
        }
    }
}

/* ==========================================================================
   Section 4
========================================================================== */
#home-section-4{
    background-image: url("../../img/home/fond-gauche.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;

    .section-content {
        img {
            width: 40px;
            height: auto;
            margin-right: 10px;
        }
    }
   
}


/* ==========================================================================
   Section 5
========================================================================== */
#home-section-5{

    .btn {
        width: 250px;
        margin: 0 auto;
    }

    #history {

        &.hide {
            height: 0px;
            overflow: hidden;
        }

        &.show {
            height: auto;
        }
    }
}
  