#home-section-0 .container-head {
  height: 100%;
  display: flex;
}

#home-section-0 .container-head .col-title {
  min-width: 700px;
  max-width: 700px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#home-section-0 .container-head .head-button {
  margin-top: 25px;
}

#home-section-0 .container-head .col-img {
  flex-grow: 1;
  align-items: center;
  margin-top: -100px;
  display: flex;
}

#home-section-0 .container-head .col-img img {
  height: auto;
  width: 100%;
}

#home-section-1 {
  height: 90vh;
  background-image: url("background-green.1f1d3618.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -20vh;
}

#home-section-1 .container .row {
  height: 100%;
  margin-top: 40vh;
}

#home-section-1 .container .row .wow {
  height: 100%;
  color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  display: flex;
}

#home-section-1 .container .row .wow img {
  height: 80px;
  margin-bottom: 40px;
}

#home-section-1 .container .row .wow p {
  text-align: center;
  text-justify: inter-word;
  margin-top: 25px;
  font-size: 18px;
}

#home-section-1 .container .row .line {
  width: 2px;
  height: 140px;
  background-color: #ccc;
  position: absolute;
  top: 65px;
  right: 0;
  transform: rotate(90deg);
}

#home-section-2 {
  background-color: var(--dark);
  margin-top: 0;
}

#home-section-2 .splide__slide {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  padding: 15px;
  display: flex;
  width: calc(20% - 25px) !important;
}

#home-section-2 .splide__slide .text-box {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  display: flex;
}

#home-section-2 .splide__slide .text-box p {
  text-align: center;
  font-size: 20px;
}

#home-section-2 .splide__slide .img-box {
  height: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#home-section-2 .splide__slide .img-box img {
  width: 150px;
  height: auto;
  margin: 25px;
}

#home-section-2 .splide__slide a {
  color: #3f4254;
  font-weight: 400 !important;
}

#home-section-2 .splide__arrow--prev {
  left: -50px;
}

#home-section-2 .splide__arrow--next {
  right: -50px;
}

#home-section-2 .splide__pagination {
  bottom: -1em;
}

#home-section-3 {
  background-image: url("fond-droit.d5362b52.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 10vh;
}

#home-section-3 .row > * {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

#home-section-3 .row > * h5 {
  text-transform: uppercase;
  font-size: 17px;
}

#home-section-3 .row > * p {
  margin-top: 10px;
  font-size: 14px;
}

#home-section-3 .img-container {
  background-color: var(--primary);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

#home-section-3 .img-container img {
  width: 50px;
  height: auto;
}

#home-section-4 {
  background-image: url("fond-gauche.b555560e.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

#home-section-4 .section-content img {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

#home-section-5 .btn {
  width: 250px;
  margin: 0 auto;
}

#home-section-5 #history.hide {
  height: 0;
  overflow: hidden;
}

#home-section-5 #history.show {
  height: auto;
}

/*# sourceMappingURL=index.fa7bfeef.css.map */
